import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Result, Typography } from 'antd';
import React, { useState } from 'react';

export default function SuccessModal({ isOpen, setIsOpen, orderNumber }) {
  return (
    <Modal
      centered
      open={isOpen}
      footer={null}
      destroyOnClose
      onCancel={() => setIsOpen(false)}
    >
      <Result
        className="p-2"
        status="success"
        title={orderNumber}
        subTitle={`Order successfully saved!`}
        extra={[
          <Button key="close" onClick={() => setIsOpen(false)}>
            Close
          </Button>,
        ]}
      />
    </Modal>
  );
}
