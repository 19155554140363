import React, { useContext, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import {
  SALES_ENTRY_ROUTE,
  SALES_ORDER_ROUTE,
  USER_MANAGEMENT_ROUTE,
  BULLET_TANK_HISTORY_REPORT_ROUTE,
  CASH_ADVANCE_REPORT_ROUTE,
  CUSTOMER_HISTORY_REPORT_ROUTE,
  EXPENSE_REPORT_ROUTE,
  INCOME_STATEMENT_REPORT_ROUTE,
  OUTLET_HISTORY_REPORT_ROUTE,
  SALES_HISTORY_REPORT_ROUTE,
  TIME_KEEPING_REPORT_ROUTE,
  BULLET_TANK_MAINTENANCE_ROUTE,
  CATEGORY_MAINTENANCE_ROUTE,
  PRODUCT_MAINTENANCE_ROUTE,
  CUSTOMER_LIST_MAINTENANCE_ROUTE,
  ACCESS_LEVEL_MAINTENANCE_ROUTE,
  OUTLET_MAINTENANCE_ROUTE,
  BRANCH_MAINTENANCE_ROUTE,
  BULLET_TANK_INVENTORY_ROUTE,
  PRICE_MAINTENANCE_ROUTE,
  SALES_ORDER_OUTLET_ROUTE,
  SALES_ENTRY_OUTLET_ROUTE,
  EXPENSE_REQUEST_OUTLET_ROUTE,
  TIME_CLOCK_ROUTE,
  END_OF_DAY_ROUTE,
  DAILY_STOCKS_REPORT_ROUTE,
  CHECKER_HISTORY_REPORT_ROUTE,
  SOLID_ASSETS_SALES_ENTRY_ROUTE,
} from './routes';
import {
  AccessMaintenance,
  BranchesMaintenance,
  BulletTankInventory,
  BulletTankMaintenance,
  BulletTankReport,
  CashAdvanceReport,
  CategoriesMaintenance,
  CheckerHistoryReport,
  CustomerHistoryReport,
  CustomerMaintenance,
  DailyStocksReport,
  Dashboard,
  EndOfDay,
  ExpenseReport,
  IncomeStatementReport,
  OutletExpenseRequest,
  OutletHistoryReport,
  OutletMaintenance,
  OutletSalesEntry,
  OutletSalesOrder,
  PriceMaintenance,
  ProductsMaintenance,
  SalesEntry,
  SalesHistoryReport,
  SalesOrder,
  SolidAssetsSales,
  TimeClock,
  TimeIn,
  TimeKeepingReport,
  TimeOut,
  UserManagement,
} from '../../pages';

import { Navigation } from '../../components';
import { UserContext } from '../context/UserContext';

export default function ActualRoute() {
  const { profile } = useContext(UserContext);

  const getRoutes = () => {
    switch (profile.accessLevel) {
      case '1':
        return (
          <>
            <Route path={SALES_ORDER_ROUTE} element={<SalesOrder />} />
            <Route path={SALES_ENTRY_ROUTE} element={<SalesEntry />} />
            <Route
              path={SOLID_ASSETS_SALES_ENTRY_ROUTE}
              element={<SolidAssetsSales />}
            />
            <Route path={END_OF_DAY_ROUTE} element={<EndOfDay />} />
            
            <Route path={USER_MANAGEMENT_ROUTE} element={<UserManagement />} />

            {/* Reports */}
            <Route
              path={BULLET_TANK_HISTORY_REPORT_ROUTE}
              element={<BulletTankReport />}
            />
            <Route
              path={CASH_ADVANCE_REPORT_ROUTE}
              element={<CashAdvanceReport />}
            />
            <Route
              path={CUSTOMER_HISTORY_REPORT_ROUTE}
              element={<CustomerHistoryReport />}
            />
            <Route
              path={CHECKER_HISTORY_REPORT_ROUTE}
              element={<CheckerHistoryReport />}
            />
            <Route path={EXPENSE_REPORT_ROUTE} element={<ExpenseReport />} />
            <Route
              path={INCOME_STATEMENT_REPORT_ROUTE}
              element={<IncomeStatementReport />}
            />
            <Route
              path={OUTLET_HISTORY_REPORT_ROUTE}
              element={<OutletHistoryReport />}
            />
            <Route
              path={SALES_HISTORY_REPORT_ROUTE}
              element={<SalesHistoryReport />}
            />
            <Route
              path={DAILY_STOCKS_REPORT_ROUTE}
              element={<DailyStocksReport />}
            />
            <Route
              path={TIME_KEEPING_REPORT_ROUTE}
              element={<TimeKeepingReport />}
            />

            {/* Maintenance */}
            <Route
              path={BULLET_TANK_MAINTENANCE_ROUTE}
              element={<BulletTankMaintenance />}
            />
            <Route
              path={CATEGORY_MAINTENANCE_ROUTE}
              element={<CategoriesMaintenance />}
            />
            <Route
              path={PRODUCT_MAINTENANCE_ROUTE}
              element={<ProductsMaintenance />}
            />
            <Route
              path={CUSTOMER_LIST_MAINTENANCE_ROUTE}
              element={<CustomerMaintenance />}
            />
            <Route
              path={ACCESS_LEVEL_MAINTENANCE_ROUTE}
              element={<AccessMaintenance />}
            />
            <Route
              path={OUTLET_MAINTENANCE_ROUTE}
              element={<OutletMaintenance />}
            />
            <Route
              path={BRANCH_MAINTENANCE_ROUTE}
              element={<BranchesMaintenance />}
            />
            <Route
              path={PRICE_MAINTENANCE_ROUTE}
              element={<PriceMaintenance />}
            />

            {/* Outlet */}
            <Route
              path={SALES_ORDER_OUTLET_ROUTE}
              element={<OutletSalesOrder />}
            />
            <Route
              path={SALES_ENTRY_OUTLET_ROUTE}
              element={<OutletSalesEntry />}
            />
            <Route
              path={EXPENSE_REQUEST_OUTLET_ROUTE}
              element={<OutletExpenseRequest />}
            />

            <Route
              path={PRICE_MAINTENANCE_ROUTE}
              element={<PriceMaintenance />}
            />

            {/* Inventory */}
            <Route
              path={BULLET_TANK_INVENTORY_ROUTE}
              element={<BulletTankInventory />}
            />

            {/* Time Keeping */}
            <Route path={TIME_CLOCK_ROUTE} element={<TimeClock />} />
          </>
        );
      case '2':
        return (
          <>
            <Route path={SALES_ORDER_ROUTE} element={<SalesOrder />} />
            <Route path={SALES_ENTRY_ROUTE} element={<SalesEntry />} />
            <Route
              path={SOLID_ASSETS_SALES_ENTRY_ROUTE}
              element={<SolidAssetsSales />}
            />
            <Route path={END_OF_DAY_ROUTE} element={<EndOfDay />} />
            <Route path={USER_MANAGEMENT_ROUTE} element={<UserManagement />} />

            {/* Reports */}
            <Route
              path={OUTLET_HISTORY_REPORT_ROUTE}
              element={<OutletHistoryReport />}
            />
            <Route
              path={SALES_HISTORY_REPORT_ROUTE}
              element={<SalesHistoryReport />}
            />
            <Route
              path={DAILY_STOCKS_REPORT_ROUTE}
              element={<DailyStocksReport />}
            />
            <Route
              path={CHECKER_HISTORY_REPORT_ROUTE}
              element={<CheckerHistoryReport />}
            />
            <Route
              path={CUSTOMER_HISTORY_REPORT_ROUTE}
              element={<CustomerHistoryReport />}
            />
            {/* Maintenance */}
            <Route
              path={PRODUCT_MAINTENANCE_ROUTE}
              element={<ProductsMaintenance />}
            />
            <Route
              path={CUSTOMER_LIST_MAINTENANCE_ROUTE}
              element={<CustomerMaintenance />}
            />
            <Route
              path={PRICE_MAINTENANCE_ROUTE}
              element={<PriceMaintenance />}
            />

            {/* Outlet */}
            <Route
              path={SALES_ORDER_OUTLET_ROUTE}
              element={<OutletSalesOrder />}
            />
            <Route
              path={SALES_ENTRY_OUTLET_ROUTE}
              element={<OutletSalesEntry />}
            />
            <Route
              path={EXPENSE_REQUEST_OUTLET_ROUTE}
              element={<OutletExpenseRequest />}
            />

            {/* Inventory */}
            <Route
              path={BULLET_TANK_INVENTORY_ROUTE}
              element={<BulletTankInventory />}
            />

            {/* Time Keeping */}
            <Route path={TIME_CLOCK_ROUTE} element={<TimeClock />} />
          </>
        );
      case '3':
        return (
          <>
            <Route path={SALES_ORDER_ROUTE} element={<SalesOrder />} />
            <Route path={SALES_ENTRY_ROUTE} element={<SalesEntry />} />
            <Route
              path={SOLID_ASSETS_SALES_ENTRY_ROUTE}
              element={<SolidAssetsSales />}
            />
            <Route path={END_OF_DAY_ROUTE} element={<EndOfDay />} />

            {/* Reports */}
            <Route
              path={SALES_HISTORY_REPORT_ROUTE}
              element={<SalesHistoryReport />}
            />
            <Route
              path={DAILY_STOCKS_REPORT_ROUTE}
              element={<DailyStocksReport />}
            />
            <Route
              path={OUTLET_HISTORY_REPORT_ROUTE}
              element={<OutletHistoryReport />}
            />
            <Route
              path={CHECKER_HISTORY_REPORT_ROUTE}
              element={<CheckerHistoryReport />}
            />
            <Route
              path={CUSTOMER_HISTORY_REPORT_ROUTE}
              element={<CustomerHistoryReport />}
            />

            {/* Maintenance */}
            <Route
              path={BULLET_TANK_MAINTENANCE_ROUTE}
              element={<BulletTankMaintenance />}
            />
            <Route
              path={CATEGORY_MAINTENANCE_ROUTE}
              element={<CategoriesMaintenance />}
            />
            <Route
              path={PRODUCT_MAINTENANCE_ROUTE}
              element={<ProductsMaintenance />}
            />
            <Route
              path={CUSTOMER_LIST_MAINTENANCE_ROUTE}
              element={<CustomerMaintenance />}
            />
            <Route
              path={ACCESS_LEVEL_MAINTENANCE_ROUTE}
              element={<AccessMaintenance />}
            />
            <Route
              path={OUTLET_MAINTENANCE_ROUTE}
              element={<OutletMaintenance />}
            />
            <Route
              path={BRANCH_MAINTENANCE_ROUTE}
              element={<BranchesMaintenance />}
            />
            <Route
              path={PRICE_MAINTENANCE_ROUTE}
              element={<PriceMaintenance />}
            />

            {/* Outlet */}
            <Route
              path={SALES_ORDER_OUTLET_ROUTE}
              element={<OutletSalesOrder />}
            />
            <Route
              path={SALES_ENTRY_OUTLET_ROUTE}
              element={<OutletSalesEntry />}
            />
            <Route
              path={EXPENSE_REQUEST_OUTLET_ROUTE}
              element={<OutletExpenseRequest />}
            />

            {/* Inventory */}
            <Route
              path={BULLET_TANK_INVENTORY_ROUTE}
              element={<BulletTankInventory />}
            />

            {/* Time Keeping */}
            <Route path={TIME_CLOCK_ROUTE} element={<TimeClock />} />
          </>
        );
      case '4':
        return (
          <>
            <Route path={SALES_ORDER_ROUTE} element={<SalesOrder />} />

            {/* Outlet */}
            <Route
              path={SALES_ORDER_OUTLET_ROUTE}
              element={<OutletSalesOrder />}
            />
            <Route
              path={SALES_ENTRY_OUTLET_ROUTE}
              element={<OutletSalesEntry />}
            />

            {/* Time Keeping */}
            <Route path={TIME_CLOCK_ROUTE} element={<TimeClock />} />
          </>
        );
      default:
        break;
    }
  };
  return (
    <Navigation>
      <Routes>
        <Route path="/" exact={true} element={<Dashboard />} />
        {getRoutes()}

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Navigation>
  );
}
